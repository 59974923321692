body {
  background-color: #0d0d0d;
  color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

a {
  color: #ADFF2F; /* Bright green-yellow */
  text-decoration: none;
}

a:hover {
  color: #9ACD32; /* Subtle change on hover */
  text-decoration: underline;
}

.App-header {
  background-color: #0d0d0d;
  color: #e0e0e0;
}

h1, h2, h3, h4, h5, h6 {
  color: #32CD32;
}

.desc-padding {
  margin-top: 5px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

@media (min-width: 768px) {
  .desc-padding {
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (min-width: 1200px) {
  .desc-padding {
    padding-bottom: 50px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}